import { CSRF, getData, getElement } from "../../../../../helpers";
import { Dt } from "../../../../../plugins/datatable";
import { AccountinggroupModal } from "./modals/store";

class AccountingGroupModule extends HTMLElement {
    connectedCallback() {
        // console.log(this);
        const dt = getElement("[data-table=dt]", this);
        const BtnNew = getElement("[name=new]", this); // Identificar el botón para crear

        //Acciones
        BtnNew?.addEventListener("click", () => {
            AccountinggroupModal.create(
                getData(BtnNew, "data-bs-target"),
                getData(BtnNew, "data-url"),
                getData(BtnNew, "title")
            );
        });

        dt?.addEventListener("click", (e) => {
            const btnEditAccountingGroups = e.target.closest(
                "[name=btnEditAccountingGroups]"
            );
            if (btnEditAccountingGroups) {
                AccountinggroupModal.edit(
                    getData(btnEditAccountingGroups, "data-bs-target"),
                    getData(btnEditAccountingGroups, "data-url"),
                    getData(btnEditAccountingGroups, "title"),
                    getData(btnEditAccountingGroups, "data-data")
                );
            }
        });

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            scrollY: "520px", // Define la altura de desplazamiento para el cuerpo de la tabla
            scrollCollapse: true, // Permite que el contenido colapse dentro del área definida
            fixedHeader: true, // Mantiene el encabezado visible al hacer scroll
        };

        configDt.ajax = {
            url: getData("accountinggroupsUrlDt"),
            type: "POST",
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };
        // Columnas del AJAX
        configDt.columns = [
            { data: "DT_RowIndex", width: "3%", className: "text-center" },
            { data: "name" },
            { data: "plans", width: "10%", className: "text-center" },
            { data: "rule" },
            { data: "increment", width: "5%", className: "text-center" },
            {
                data: "buttons",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
        ];

        const DtAgs = new Dt(configDt);
        DtAgs.dataTable(dt);
        // End DataTable
    }
}

window.customElements.define("accountinggroups-module", AccountingGroupModule, {
    extends: "section",
});
