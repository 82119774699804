import { alertHTML, bodyRequest, CSRF, getData, getElement, requestOptions, tryAsyncFetch } from "../../../helpers";
import { Dt } from "../../../plugins/datatable";

class EntriesModule extends HTMLElement {
    async connectedCallback() {
        // console.log(this);
        const dt = getElement("[data-table=dt]", this);

        const BtnSearch = getElement("[name=search]", this);
        const BtnReset = getElement("[name=reset]", this);

        let filtro = {
            start: "",
            end: "",
            description: "",
        };

        dt?.addEventListener("click", async (e) => {
            const Target = e.target;
            
            if (Target.name == "checked") {
                bodyRequest.set("checked", Target.checked);

                let response = await tryAsyncFetch(
                    getData(Target, "data-url"),
                    requestOptions
                );

                if (response.error) {
                    Target.checked = !Target.checked;
                    alertHTML(response.error);
                }
            }
        });

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            searching: false,
            ordering: false,
            scrollY: "450px", // Define la altura de desplazamiento para el cuerpo de la tabla
            scrollCollapse: true, // Permite que el contenido colapse dentro del área definida
            fixedHeader: true, // Mantiene el encabezado visible al hacer scroll
        };

        configDt.ajax = {
            url: getData("entriesUrlDt"),
            type: "POST",
            data: function (data) {
                data.startDate = filtro.start;
                data.endDate = filtro.end;
                data.description = filtro.description;
            },
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };
        // Columnas del AJAX
        configDt.columns = [
            { data: "entry", width: "3%", className: "text-center" },
            { data: "date" },
            { data: "description" },
            { data: "lines" },
            { data: "debit", width: "7%", className: "text-end" },
            { data: "credit", width: "7%", className: "text-end" },
            { data: "balance", width: "7%", className: "text-end" },
            { data: "checked", width: "5%", className: "text-center" },
            {
                data: "buttons",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
        ];

        // configDt.order = [[0, "desc"]];

        const DtAgs = new Dt(configDt);
        let T = await DtAgs.dataTable(dt);
        // End DataTable

        BtnSearch?.addEventListener("click", () => {
            filtro.start = getElement("[name=startDate]", this).value;
            filtro.end = getElement("[name=endDate]", this).value;
            filtro.description = getElement("[name=description]", this).value;
            T.ajax.reload();
        });

        BtnReset?.addEventListener("click", () => {
            getElement("[name=description]", this).value = "";
            filtro.description = "";
            getElement("[name=startDate]", this).value = "";
            filtro.start = "";
            getElement("[name=endDate]", this).value = "";
            filtro.end = "";
            T.ajax.reload();
        });
    }
}

window.customElements.define("entries-module", EntriesModule, {
    extends: "section",
});
