import {
    CSRF,
    alertHTML,
    bodyRequest,
    getData,
    getElement,
    requestOptions,
} from "../../../../../helpers";
import { Dt } from "../../../../../plugins/datatable";

class AccountingDefaultModule extends HTMLElement {
    connectedCallback() {
        this.load();
    }

    load() {
        // console.log(this);
        const DtElement = getElement("[data-table=dt]", this);
        DtElement.addEventListener("aj:select2", (event) => {
            const idElement = getData(event.target, "data-defaultid");
            const dataSelected = event.detail.data;
            bodyRequest.set("accountingplan_id", dataSelected.id);
            bodyRequest.set("id", idElement);
            fetch(getData(event.target, "data-url"), requestOptions)
                .then((r) => r.json())
                .then((data) => {
                    if (data)
                        alertHTML(getData(event.target, "data-ok"), "success");
                    else alertHTML(getData(event.target, "data-error"));
                });
        });

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            scrollY: "520px", // Define la altura de desplazamiento para el cuerpo de la tabla
            scrollCollapse: true, // Permite que el contenido colapse dentro del área definida
            fixedHeader: true, // Mantiene el encabezado visible al hacer scroll
        };

        configDt.ajax = {
            url: getData("accountingsdefaultUrlDt"),
            type: "POST",
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };
        // Columnas del AJAX
        configDt.columns = [{ data: "description" }, { data: "plans" }];

        const DtAp = new Dt(configDt);
        DtAp.dataTable(DtElement);
        // End DataTable
    }
}

window.customElements.define(
    "accountingsdefault-module",
    AccountingDefaultModule,
    {
        extends: "section",
    }
);
