import { CSRF, getData, getElement } from "../../../../helpers";
import { Dt } from "../../../../plugins/datatable";
import { PaymentmethodModal as Paymentmethod } from "./modals/store";

class PaymentmethodModule extends HTMLElement {
    connectedCallback() {
        this.load();
    }

    load() {
        // console.log(this);
        const DtElement = getElement("[data-table=dt]", this);
        const BtnNew = getElement("[name=new]", this); // Identificar el botón para crear

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            scrollY: "500px", // Define la altura de desplazamiento para el cuerpo de la tabla
            scrollCollapse: true, // Permite que el contenido colapse dentro del área definida
            fixedHeader: true, // Mantiene el encabezado visible al hacer scroll
        };

        configDt.ajax = {
            url: getData("paymentmethodUrlDt"),
            type: "POST",
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };
        // Columnas del AJAX
        configDt.columns = [
            { data: "DT_RowIndex", width: "3%", className: "text-center" },
            { data: "name" },
            { data: "description" },
            { data: "days", width: "5%", className: "text-center" },
            {
                data: "type",
                className: "text-center",
            },
            {
                data: "need_bank",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
            { data: "sales", width: "5%", className: "text-center" },
            { data: "purchases", width: "5%", className: "text-center" },
            // {
            //     data: "status",
            //     width: "5%",
            //     className: "text-center",
            //     orderable: false,
            // },
            {
                data: "buttons",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
        ];

        const DtAps = new Dt(configDt);
        DtAps.dataTable(DtElement);
        // End DataTable

        // Eventos
        BtnNew?.addEventListener("click", (e) => {
            Paymentmethod.create(
                getData(BtnNew, "data-bs-target"),
                getData(BtnNew, "title"),
                getData(BtnNew, "data-url")
            );
        });

        DtElement.addEventListener("click", (e) => {
            const Target = e.target;
            const BtnEdit = Target.closest(".edit");

            if (BtnEdit) {
                Paymentmethod.edit(
                    getData(BtnEdit, "data-bs-target"),
                    getData(BtnEdit, "title"),
                    getData(BtnEdit, "data-url"),
                    getData(BtnEdit, "data-data"),
                    getData(BtnEdit, "data-type")
                );
            }
        });
    }
}

window.customElements.define("paymentmethod-module", PaymentmethodModule, {
    extends: "section",
});
