import $ from "jquery/dist/jquery";
import daterangepicker from "daterangepicker";
import {
    Management,
    firstEspecialChar,
    getData,
    getElement,
    readTranslations,
} from "../helpers";
import moment from "moment";
import inputmask from "inputmask";

// import "moment/locale/es";
export class DateRangePicker {
    #input = null;
    #configDt = {
        singleDatePicker: true,
        autoUpdateInput: false,
        showDropdowns: true,
        // esta opción solo usar en rangos de años
        // minYear: parseInt(localStorage.getItem(Management.slug)),
        // maxYear: parseInt(localStorage.getItem(Management.slug)),
        minDate: moment()
            .year(parseInt(localStorage.getItem(Management.slug)))
            .startOf("year"),
        maxDate: moment()
            .year(parseInt(localStorage.getItem(Management.slug)))
            .endOf("year"),
        startDate: moment()
            .year(parseInt(localStorage.getItem(Management.slug)))
            .startOf("day"),
        locale: {
            format: "DD/MM/YYYY",
            firstDay: 1, // Lunes como primer día de la semana
            applyLabel: "Aplicar",
            cancelLabel: "Borrar",
            fromLabel: "Desde",
            toLabel: "Hasta",
            customRangeLabel: "Rango Personalizado",
            weekLabel: "S",
            daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
            monthNames: [
                "Enero",
                "Febrero",
                "Marzo",
                "Abril",
                "Mayo",
                "Junio",
                "Julio",
                "Agosto",
                "Septiembre",
                "Octubre",
                "Noviembre",
                "Diciembre",
            ],
        },
    };
    linked = [];

    constructor(input, object = {}) {
        this.#input = input;
        this.#configDt = { ...this.#configDt, ...object };
    }

    async picker() {
        const Translations = await readTranslations();
        this.#configDt.locale.format =
            Translations["view.plugins.daterangepicker.locale.format"];
        this.#configDt.locale.applyLabel =
            Translations["view.plugins.daterangepicker.locale.applyLabel"];
        this.#configDt.locale.cancelLabel =
            Translations["view.plugins.daterangepicker.locale.cancelLabel"];
        this.#configDt.locale.fromLabel =
            Translations["view.plugins.daterangepicker.locale.fromLabel"];
        this.#configDt.locale.toLabel =
            Translations["view.plugins.daterangepicker.locale.toLabel"];
        this.#configDt.locale.customRangeLabel =
            Translations[
                "view.plugins.daterangepicker.locale.customRangeLabel"
            ];
        this.#configDt.locale.weekLabel =
            Translations["view.plugins.daterangepicker.locale.weekLabel"];
        this.#configDt.locale.daysOfWeek =
            Translations[
                "view.plugins.daterangepicker.locale.daysOfWeek"
            ].split(",");
        this.#configDt.locale.monthNames =
            Translations[
                "view.plugins.daterangepicker.locale.monthNames"
            ].split(",");

        // let currentDate = moment().startOf("day");
        // this.#configDt.startDate = currentDate;
        // this.#configDt.isInvalidDate = function (date) {
        //     // Permitir solo la fecha actual
        //     return !date.isSame(currentDate, "day");
        // };

        if (this.#input.hasAttribute("data-not-validate-year")) {
            this.#configDt.minDate = moment().year(2000).startOf("year");
            this.#configDt.maxDate = moment().add(10, "years").endOf("year");
        }

        let drp = $(this.#input).daterangepicker(this.#configDt)[0];

        this.load();

        return drp;
    }

    load() {
        // esto funciona perfectamente pero elimina la posibilidad de escribir texto
        // let fm = this.#configDt.locale.format.replace(/[a-zA-Z0-9]/g, "#");
        // const DateMask = new inputmask(fm);
        // DateMask.mask(this.#input);

        // echa por defecto
        if (this.#input.hasAttribute("data-default-date")) {
            let date = moment().year(
                parseInt(localStorage.getItem(Management.slug))
            );

            if (!this.#input.value.trim()) {
                $(this.#input).val(date.format(this.#configDt.locale.format));
            }
        }

        this.addEvents();
    }

    addEvents() {
        $(this.#input).on("cancel.daterangepicker", (e, picker) => {
            // Borrar su valor
            $(this.#input).val("");

            this.disabledLinked();
        });

        $(this.#input).on("apply.daterangepicker", (e, picker) => {
            $(this.#input).val(
                picker.startDate.format(this.#configDt.locale.format)
            );

            this.setLimitStarDate(picker);
            this.checkMinDate(picker);

            // Este evento es predeterminado de los navegadores por lo que es útil tenelo;
            // crear evento Personalizado
            const CE = new CustomEvent("aj:daterangepicker", {
                detail: { data: this.#input },
                bubbles: true,
                cancelable: true,
            });

            this.#input.dispatchEvent(CE);
        });

        $(this.#input).on("show.daterangepicker", (e, picker) => {
            if (this.#input.classList.contains("same-disabled")) {
                setTimeout(function () {
                    picker.hide();
                }, 50);
            }
        });

        this.#input.addEventListener("change", (e) => {
            let picker = $(this.#input).data("daterangepicker");
            let value = this.#input.value.toLowerCase();
            if (value) {
                let date = moment().year(
                    parseInt(localStorage.getItem(Management.slug))
                );
                // Esta funcionalidad no está optimizada para multidiomas
                switch (value) {
                    case "h":
                    case "hoy":
                    case "now":
                    case "today":
                        picker.setStartDate(date);
                        picker.setEndDate(date);

                        break;
                    case "l":
                        picker.setStartDate(date.startOf("week"));
                        picker.setEndDate(date.startOf("week"));
                        break;
                    case "m":
                        picker.setStartDate(
                            date.startOf("week").add(1, "days")
                        );
                        picker.setEndDate(date.startOf("week").add(1, "days"));
                        break;
                    case "x":
                        picker.setStartDate(
                            date.startOf("week").add(2, "days")
                        );
                        picker.setEndDate(date.startOf("week").add(2, "days"));
                        break;
                    case "j":
                        picker.setStartDate(
                            date.startOf("week").add(3, "days")
                        );
                        picker.setEndDate(date.startOf("week").add(3, "days"));
                        break;
                    case "v":
                        picker.setStartDate(
                            date.startOf("week").add(4, "days")
                        );
                        picker.setEndDate(date.startOf("week").add(4, "days"));
                        break;
                    case "s":
                        picker.setStartDate(
                            date.startOf("week").add(5, "days")
                        );
                        picker.setEndDate(date.startOf("week").add(5, "days"));
                        break;
                    case "d":
                        picker.setStartDate(date.endOf("week"));
                        picker.setEndDate(date.endOf("week"));
                        break;

                    default:
                        var dateFormat = picker.locale.format;

                        if (isNaN(value)) {
                            var char = firstEspecialChar(value);
                            var data = value.split(char);
                            data = data.map((i) => parseInt(i));
                            var df = dateFormat.toLowerCase();
                            var c = firstEspecialChar(df);
                            df = df.split(c);
                            df.forEach((item, key) => {
                                // primero definir el año para que la fecha tenga una base de comparación
                                // comprobar si es año
                                if (item == "yyyy") {
                                    // si existe el año en el input y tiene el atributo no validar año setearlo
                                    if (
                                        this.#input.hasAttribute(
                                            "data-not-validate-year"
                                        ) &&
                                        data[key]
                                    ) {
                                        date.year(data[key]);

                                        picker.setStartDate(
                                            date.format(dateFormat)
                                        );
                                        picker.setEndDate(
                                            date.format(dateFormat)
                                        );
                                    }
                                }

                                // primero definir el mes para que el día tenga una base de comparación
                                // comprobar si es mes
                                if (item == "mm") {
                                    // comprobar valor ingresado
                                    if (data[key]) {
                                        // comprobar si es mayor al año de lo contrario hace cosas raras con el día
                                        if (data[key] > 12) {
                                            date.month(11);
                                        } else {
                                            date.month(data[key] - 1);
                                        }

                                        picker.setStartDate(
                                            date.format(dateFormat)
                                        );
                                        picker.setEndDate(
                                            date.format(dateFormat)
                                        );
                                    }
                                }

                                if (item == "dd") {
                                    if (data[key]) {
                                        if (
                                            data[key] >
                                            date.endOf("month").date()
                                        ) {
                                            picker.setStartDate(
                                                date
                                                    .endOf("month")
                                                    .format(dateFormat)
                                            );
                                            picker.setEndDate(
                                                date
                                                    .endOf("month")
                                                    .format(dateFormat)
                                            );
                                        } else {
                                            picker.setStartDate(
                                                date
                                                    .date(data[key])
                                                    .format(dateFormat)
                                            );
                                            picker.setEndDate(
                                                date
                                                    .date(data[key])
                                                    .format(dateFormat)
                                            );
                                        }
                                    }
                                }
                            });
                        } else {
                            value = parseInt(value);

                            if (value > date.endOf("month").date()) {
                                picker.setStartDate(
                                    date.endOf("month").format(dateFormat)
                                );
                                picker.setEndDate(
                                    date.endOf("month").format(dateFormat)
                                );
                            } else {
                                picker.setStartDate(
                                    date.date(value).format(dateFormat)
                                );
                                picker.setEndDate(
                                    date.date(value).format(dateFormat)
                                );
                            }

                            if (dateFormat.startsWith("MM")) {
                                picker.setStartDate(
                                    date

                                        .month(parseInt(value) - 1)
                                        .format(dateFormat)
                                );
                                picker.setEndDate(
                                    date

                                        .month(parseInt(value) - 1)
                                        .format(dateFormat)
                                );
                            }
                        }
                        break;
                }

                $(this.#input).val(
                    picker.startDate.format(this.#configDt.locale.format)
                );

                // Esto se usa para las fechas que están linkeadas
                this.setLimitStarDate(picker);
                this.checkMinDate(picker);
            } else {
                this.disabledLinked();
            }
        });

        this.#input.addEventListener("input", (e) => {
            let value = this.#input.value;

            value = this.formatDateInput(value, this.#configDt.locale.format);

            this.#input.value = value;
        });

        // Evento lanzado por el selector de año intersectar en el body
        document.body.addEventListener("aj:startYear", (e) => {
            let picker = $(this.#input).data("daterangepicker");
            let date = moment().year(
                parseInt(localStorage.getItem(Management.slug))
            );

            if (!this.#input.hasAttribute("data-not-validate-year")) {
                // Se debe clonar para que se aplique correctamente la fecha
                picker.minDate = date.clone().startOf("year");
                picker.maxDate = date.clone().endOf("year");
            }

            // Comprobar si se debe actualizar la fecha
            if (this.#input.hasAttribute("data-default-date")) {
                $(this.#input).val(
                    date.clone().format(this.#configDt.locale.format)
                );
            }

            picker.setStartDate(date.clone().startOf("day"));
            picker.setEndDate(date.clone().endOf("day"));

            picker.updateView();
        });
    }

    formatDateInput(value, format) {
        if (!/^\d/.test(value)) {
            return value;
        }

        const separators = format.match(/[^a-zA-Z]/g); // Extraer separadores del formato
        const parts = format.split(/[^a-zA-Z]/); // Dividir el formato en partes (día, mes, año)
        let currentValue = value.replace(/[^0-9]/g, ""); // Remover todo excepto números
        let result = "";
        let currentIndex = 0;

        for (let i = 0; i < parts.length; i++) {
            const partLength = parts[i].length;

            if (currentValue.length >= currentIndex + partLength) {
                result += currentValue.slice(
                    currentIndex,
                    currentIndex + partLength
                );
                currentIndex += partLength;
            } else {
                result += currentValue.slice(currentIndex);
                currentIndex = currentValue.length;
            }

            if (separators[i] && currentIndex < currentValue.length) {
                result += separators[i];
            }
        }

        return result;
    }

    checkMinDate(picker) {
        const Link = this.hasLink();
        if (Link) {
            let startDate = moment(
                this.#input.value,
                this.#configDt.locale.format,
                true
            );
            if (startDate.isBefore(picker.minDate)) {
                $(this.#input).val("");
            }
        }
    }

    disabledLinked() {
        // Deshabilitar y borrar el valor del elemento linkeado
        const Linked = this.hasLinked();
        if (Linked) {
            this.setOrRemoveAttribute(Linked, {
                disabled: "",
                value: "",
            });
        }
    }

    setLimitStarDate(picker) {
        const Linked = this.hasLinked();

        if (Linked) {
            this.setOrRemoveAttribute(Linked, { disabled: "" }, "remove");
            const endDatePicker = $(Linked).data("daterangepicker");

            if (endDatePicker) {
                endDatePicker.minDate = picker.startDate.add("1", "day");

                let days = endDatePicker.startDate.diff(
                    picker.startDate,
                    "days"
                );

                if (days < 0) {
                    $(Linked).val("");
                }
            }
        }
    }

    hasLinked() {
        if (this.#input.hasAttribute("data-linked")) {
            return getElement(getData(this.#input, "data-linked"));
        }
        return null;
    }

    hasLink() {
        if (this.#input.hasAttribute("data-link")) {
            return getElement(getData(this.#input, "data-link"));
        }
        return null;
    }

    setOrRemoveAttribute = (element, attributes = {}, action = "set") => {
        for (const key in attributes) {
            if (action === "set") {
                element?.setAttribute(key, attributes[key]);

                if (key === "value") {
                    $(element)?.val(attributes[key]);
                }
            } else {
                element?.removeAttribute(key);
            }
        }
    };
}
