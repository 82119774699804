import { CSRF, getData, getElement } from "../../../../../helpers";
import { Dt } from "../../../../../plugins/datatable";
import { JournalModal } from "./modals/store";

class JournalModule extends HTMLElement {
    connectedCallback() {
        console.log(this);
        const dt = getElement("[data-table=dt]", this);
        const BtnNew = getElement("[name=new]", this); // Identificar el botón para crear

        //Acciones
        BtnNew?.addEventListener("click", () => {
            JournalModal.create(
                getData(BtnNew, "data-bs-target"),
                getData(BtnNew, "data-url"),
                getData(BtnNew, "title")
            );
        });

        dt?.addEventListener("click", (e) => {
            const btnEditJournals = e.target.closest("[name=btnEditJournals]");
            if (btnEditJournals) {
                JournalModal.edit(
                    getData(btnEditJournals, "data-bs-target"),
                    getData(btnEditJournals, "data-url"),
                    getData(btnEditJournals, "title"),
                    getData(btnEditJournals, "data-data")
                );
            }
        });

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            scrollY: "520px", // Define la altura de desplazamiento para el cuerpo de la tabla
            scrollCollapse: true, // Permite que el contenido colapse dentro del área definida
            fixedHeader: true, // Mantiene el encabezado visible al hacer scroll
        };

        configDt.ajax = {
            url: getData("journalsUrlDt"),
            type: "POST",
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };
        // Columnas del AJAX
        configDt.columns = [
            { data: "DT_RowIndex", width: "3%", className: "text-center" },
            { data: "code" },
            { data: "name" },
            { data: "plans" },
            {
                data: "buttons",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
        ];

        const DtAgs = new Dt(configDt);
        DtAgs.dataTable(dt);
        // End DataTable
    }
}

window.customElements.define("journals-module", JournalModule, {
    extends: "section",
});
