import { CSRF, getData, getElement } from "../../../../../helpers";
import { Dt } from "../../../../../plugins/datatable";

class EntryTemplatesModule extends HTMLElement {
    async connectedCallback() {
        // console.log(this);
        const dt = getElement("[data-table=dt]", this);

        const BtnSearch = getElement("[name=search]", this);
        const BtnReset = getElement("[name=reset]", this);

        let filtro = {
            start: "",
            end: "",
            description: "",
        };

        dt?.addEventListener("click", (e) => {
            const btnEditJournals = e.target.closest("[name=btnEditJournals]");
            if (btnEditJournals) {
                // JournalModal.edit(
                //     btnEditJournals.getAttribute("data-bs-target"),
                //     btnEditJournals.getAttribute("data-url"),
                //     btnEditJournals.title,
                //     btnEditJournals.getAttribute("data-data")
                // );
            }
        });

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            searching: false,
            scrollY: "520px", // Define la altura de desplazamiento para el cuerpo de la tabla
            scrollCollapse: true, // Permite que el contenido colapse dentro del área definida
            fixedHeader: true, // Mantiene el encabezado visible al hacer scroll
        };

        configDt.ajax = {
            url: getData("entryTemplatesUrlDt"),
            type: "POST",
            data: function (data) {
                data.startDate = filtro.start;
                data.endDate = filtro.end;
                data.description = filtro.description;
            },
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };
        // Columnas del AJAX
        configDt.columns = [
            { data: "DT_RowIndex", width: "3%", className: "text-center" },
            { data: "description" },
            { data: "lines" },
            {
                data: "buttons",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
        ];

        configDt.order = [[0, "desc"]];

        const DtAgs = new Dt(configDt);
        let T = await DtAgs.dataTable(dt);
        // End DataTable

        BtnSearch?.addEventListener("click", () => {
            filtro.start = getElement("[name=start]", this).value;
            filtro.end = getElement("[name=end]", this).value;
            filtro.description = getElement("[name=description]", this).value;
            T.ajax.reload();
        });

        BtnReset?.addEventListener("click", () => {
            getElement("[name=description]", this).value = "";
            filtro.description = "";
            getElement("[name=start]", this).value = "";
            filtro.start = "";
            getElement("[name=end]", this).value = "";
            filtro.end = "";
            T.ajax.reload();
        });
    }
}

window.customElements.define("entrytemplates-module", EntryTemplatesModule, {
    extends: "section",
});
