import {
    CSRF,
    cleanFloat,
    formatCurrency,
    getData,
    getElement,
    resetSelect2Ajax,
} from "../../../helpers";
import { Dt } from "../../../plugins/datatable";

class BalancesModule extends HTMLElement {
    async connectedCallback() {
        //console.log(this);
        const DtElement = getElement("[data-table=dt]", this);
        const BtnAccount = getElement("[name=tipo-account]", this);
        const BtnsubAccount = getElement("[name=tipo-subaccount]", this);
        const BtnSearch = getElement("[name=search]", this);
        const BtnReset = getElement("[name=reset]", this);
        const Form = getElement("[name=formFilter]", this);

        let filter = {
            transactions: "transactions",
            project: null,
            startDate: null,
            endDate: null,
            accountstart: null,
            accountend: null,
        };

        let type = "subaccounting";

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            searching: false,
            ordering: false,
            scrollY: "390px", // Define la altura de desplazamiento para el cuerpo de la tabla
            scrollCollapse: true, // Permite que el contenido colapse dentro del área definida
            fixedHeader: true, // Mantiene el encabezado visible al hacer scroll
        };

        configDt.rowGroup = {
            dataSrc: "pname",
            startClassName: "heading-group",
            endClassName: "footer-group",
            startRender: (rows, group) => {
                let data = rows.data().toArray();

                let debit = data.reduce((acc, item) => {
                    if (item.debit != "") {
                        let debit = cleanFloat(
                            formatCurrency(item.debit, "decimal")
                        );
                        acc += parseFloat(debit);
                    }

                    return acc;
                }, 0);

                let credit = data.reduce((acc, item) => {
                    if (item.credit != "") {
                        let credit = cleanFloat(
                            formatCurrency(item.credit, "decimal")
                        );
                        acc += parseFloat(credit);
                    }

                    return acc;
                }, 0);

                let balance = debit - credit;
                let label = getData(this, "data-s0");

                if (balance > 0) {
                    label = getData(this, "data-sd");
                } else if (balance < 0) {
                    label = getData(this, "data-sc");
                } else {
                    label = getData(this, "data-s0");
                }

                balance = formatCurrency(Math.abs(balance), "decimal");

                let tr = document.createElement("tr");

                tr.className = "heading-group";
                let td = document.createElement("th");
                td.innerHTML = group;
                td.colSpan = 3;
                tr.appendChild(td);
                let td2 = document.createElement("th");
                td2.setAttribute("class", "text-end");
                td2.innerHTML = formatCurrency(debit, "decimal");
                tr.appendChild(td2);
                let td3 = document.createElement("th");
                td3.setAttribute("class", "text-end");
                td3.innerHTML = formatCurrency(credit, "decimal");
                tr.appendChild(td3);
                let td4 = document.createElement("th");
                td4.setAttribute("class", "text-end");
                td4.innerHTML = label + balance;
                tr.appendChild(td4);
                return tr;
            },
        };

        configDt.ajax = {
            url: getData("balancesUrlDt"),
            type: "POST",
            data: function (data) {
                data.transactions = filter.transactions;
                data.project = filter.project;
                data.startDate = filter.startDate;
                data.endDate = filter.endDate;
                data.accountstart = filter.accountstart;
                data.accountend = filter.accountend;
                data.filter = filter;
                data.type = type;
            },
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
            error: function (xhr, status, error) {
                console.error("Error fetching data from server:", error);
                console.error("Status:", status);
                console.error("Response:", xhr.responseText);
            },
        };

        // Columnas del AJAX
        configDt.columns = [
            { data: "DT_RowIndex", width: "3%", className: "text-center" },
            { data: "code" },
            { data: "name" },
            {
                data: "debit",
                width: "8%",
                className: "text-end",
                render: (data, type, row) => {
                    return formatCurrency(
                        cleanFloat(formatCurrency(data, "decimal")),
                        "decimal"
                    );
                },
            },
            {
                data: "credit",
                width: "8%",
                className: "text-end",
                render: (data, type, row) => {
                    return formatCurrency(
                        cleanFloat(formatCurrency(data, "decimal")),
                        "decimal"
                    );
                },
            },
            {
                data: "balance",
                width: "8%",
                className: "text-end",
            },
        ];

        const DtAp = new Dt(configDt);
        let T = await DtAp.dataTable(DtElement);

        // End DataTable

        //Acciones
        BtnAccount?.addEventListener("click", () => {
            type = "accounting";
            T.ajax.reload();
        });
        BtnsubAccount?.addEventListener("click", () => {
            type = "subaccounting";
            T.ajax.reload();
        });

        BtnSearch?.addEventListener("click", () => {
            let body = new FormData(Form);
            filter = Array.from(body).reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});

            T.ajax.reload();
        });

        BtnReset?.addEventListener("click", () => {
            resetSelect2Ajax(getElement("[name=project]", this));
            resetSelect2Ajax(getElement("[name=accountstart]", this));
            resetSelect2Ajax(getElement("[name=accountend]", this));

            filter = {
                transactions: "transactions",
                project: null,
                startDate: null,
                endDate: null,
                accountstart: null,
                accountend: null,
            };

            T.ajax.reload();
        });
    }
}

window.customElements.define("balances-module", BalancesModule, {
    extends: "section",
});
