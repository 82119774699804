import { SelectDos } from "../plugins/select2";
import { getData, CSRF } from "../helpers";

export const dataCompany = { companies: [] };

/**
 * Implementar el plugin Select2 para las empresas
 *
 */
export class SelectCompany extends HTMLSelectElement {
    async connectedCallback() {
        let management = getData(this, "data-management");

        let configSelect = {
            ajax: {
                url: getData("companiesUrlSelect"),
                type: "post",
                dataType: "json",
                cache: true,
                data: (param) => {
                    // Evitar filtrado en select no deseados
                    let filter = [];
                    if (this.name == "changeCompany") {
                        filter = [];
                    } else {
                        filter = dataCompany.companies;
                    }
                    return {
                        _token: CSRF,
                        search: param.term,
                        management: management,
                        companies: filter,
                        // limit: 10,
                    };
                },
                processResults: function (response) {
                    // response.forEach((element) => {
                    //     element.id = element.alpha2;
                    // });
                    return {
                        results: response,
                    };
                },
            },
            // templateResult: (data) => ucwords(data.text),
            // templateSelection: (data) => {
            //     if (!data.id) {
            //         return data.text;
            //     }
            //     return `${data.alpha2}`;
            // },
        };

        const SD = new SelectDos(configSelect);
        const SC = await SD.selectDos(this);

        SC[0]?.addEventListener("aj:select2", (e) => {
            const Data = e.detail.data;
            dataCompany.companies.push(Data.id);
        });

        SC[0]?.addEventListener("aj:unselecting", (e) => {
            const Data = e.detail.data;
            dataCompany.companies = dataCompany.companies.filter(
                (item) => item != Data.id
            );
        });
    }
}

window.customElements.define("select-company", SelectCompany, {
    extends: "select",
});
