import {
    b64Uri,
    bodyRequest,
    defaultSelect2,
    getData,
    getElement,
    getJson,
    requestOptions,
    resetForm,
    setSelect2Ajax,
} from "../../../../../helpers";
import { addresses } from "../../addresses/modals/modalAddress";
import Swal from "sweetalert2/dist/sweetalert2";

export class CompanyModal extends HTMLDivElement {
    connectedCallback() {
        this.load();
        this.validateCertificate();
    }

    test() {
        // Ese método sirve solo para hacer pruebas, no borrar el método pero si su contenido
    }

    load() {
        const Form = getElement("[data-form=form]", this);

        Form.elements.save.addEventListener("click", () => {
            if (Form.reportValidity()) {
                Form.submit();
            }
        });

        // Para el modal cuando sea de proveedor
        Form.addEventListener("change", (e) => {
            const Target = e.target;
            if (Target.name == "cif") {
                this.exists(Form);
            }

            // Para modal de proveedor
            if (Target.name == "supplier" || Target.name == "creditor") {
                if (
                    !Form.elements.supplier.value &&
                    Form.elements.creditor.value
                ) {
                    Form.elements.supplier.removeAttribute("required");
                    Form.elements.creditor.setAttribute("required", "");
                } else {
                    Form.elements.creditor.removeAttribute("required");
                    Form.elements.supplier.setAttribute("required", "");
                }
            }

            if (Target.name == "start_year") {
                let max = parseInt(getData(Target, "data-max"));
                let min = parseInt(getData(Target, "data-min"));
                let value = parseInt(Target.value);

                Target.setCustomValidity("");

                if (value > max) {
                    Target.setCustomValidity(
                        getData(Target, "data-max-message")
                    );
                }

                if (value < min) {
                    Target.setCustomValidity(
                        getData(Target, "data-min-message") + min
                    );
                }
            }
        });

        this.validateCertificate();
    }

    static create(modal, title, url) {
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
        addresses.addresses = [];
        defaultSelect2(
            Form.elements.entity_id,
            "sociedad limitada",
            "entitiesUrlSelect"
        );

        Form.elements?.start_year?.removeAttribute("disabled");
    }

    static edit(modal, title, url, data) {
        data = getJson(b64Uri(data, "decode"));

        // Reestructurar datos de contactos y direcciones
        data.addresses?.forEach((address, index) => {
            address.id = index + 1;
            address.buttons = null;

            address.contacts.forEach((contact, key) => {
                contact.id = key + 1;
                contact.buttons = null;
            });
        });

        const Form = getElement("[data-form=form]", getElement(modal));

        resetForm(Form, title, url);

        const Img = getElement("[is=input-image]", Form)?.firstElementChild
            .firstElementChild;
        Img.src = "/images/companies/" + data.logo;

        Form.elements.name.value = data.name;
        Form.elements.tradename.value = data.tradename;
        Form.elements.cif.value = data.cif;
        Form.elements.website.value = data.website;

        if (Form.elements?.fiscal_period) {
            Form.elements.fiscal_period.value =
                data?.fiscal_period ?? data?.management?.fiscal_period;
        }

        if (Form.elements?.start_year) {
            Form.elements.start_year.value = data?.management.years[0].year; // el orden ya lo agregué desde el query

            Form.elements.start_year.removeAttribute("disabled");

            if (
                data?.management?.entries_count ||
                data?.management?.sales_count ||
                data?.management?.purchases_count
            ) {
                Form.elements.start_year.setAttribute("disabled", "");
            }
        }

        Form.elements.addresses.value = b64Uri(data.addresses);

        setSelect2Ajax(
            Form.elements.entity_id,
            data.entity.id,
            data.entity.name
        );

        addresses.addresses = data.addresses;
    }

    async exists(form) {
        if (form.elements.cif.value.trim()) {
            bodyRequest.set("cif", form.elements.cif.value);
            const Request = await fetch(
                getData("companiesUrlSearch"),
                requestOptions
            );
            const Response = await Request.json();

            if (Response.success) {
                const Data = Response.success;
                form.elements.name.value = Data.name;
                form.elements.tradename.value = Data.tradename;
                form.elements.website.value = Data.website;
                let translate = getJson(
                    atob(getData(form.elements.cif, "data-exist"))
                );
                let title = translate.title;
                let text = translate.text;
                let confirmButtonText = translate.add;
                let cancelButtonText = translate.cancel;

                Swal.fire({
                    title: title,
                    text: text,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: confirmButtonText,
                    cancelButtonText: cancelButtonText,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.value) {
                        form.submit();
                    } else {
                        form.reset();
                    }
                });
            }
        }
    }

    validateCertificate() {
        const CertFile = getElement("[name=cert_file]", this);
        if (CertFile) {
            const CertPass = getElement("[name=cert_password]", this);
            CertFile.addEventListener("input", () => {
                const Cert = CertFile.files[0];

                if (Cert) {
                    CertPass.setAttribute("required", "");

                    if (
                        Cert.type != "application/x-pkcs12" &&
                        Cert.type != "application/pkcs12"
                    ) {
                        CertFile.setCustomValidity(
                            getData(CertFile, "data-accept")
                        );
                        CertFile.value = "";
                        CertPass.removeAttribute("required");
                        CertFile.reportValidity();
                    }
                } else {
                    CertPass.removeAttribute("required");
                }
            });
        }
    }
}

window.customElements.define("company-modal", CompanyModal, {
    extends: "div",
});
