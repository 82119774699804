import { CSRF, bodyRequest, formatCurrency, getData, getElement, requestOptions, resetSelect2Ajax } from "../../../helpers";
import { Dt } from "../../../plugins/datatable";

class CustomBalancesModule extends HTMLElement {

    async connectedCallback() {
        const BtnExport = getElement("[name=export]", this);
        const Form = getElement("[name=formexport]", this);
        BtnExport.addEventListener("click",() => {
            if (Form.reportValidity()) {
                Form.submit();
            }
        });
        

        
    }
}

window.customElements.define("custombalances-module", CustomBalancesModule, {
    extends: "section",
});
